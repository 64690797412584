<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
      <div class="mt-6 flex items-center justify-between px-6">
          <h4>{{ Object.entries(this.data).length === 0 ? "FAIRE UNE" : "MODIFICATION D'UNE" }} ADHÉSION </h4>
          <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
      </div>
      <vs-divider class="mb-0"></vs-divider>
      <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
        <div class="p-6">
          <p> Nom du pack <b style="color: #ff6141" >*</b> </p>
          <v-select
            v-validate="'required'"
            name="pack"
            :reduce="rep => rep.id"
            class="w-full" label="name"
            v-model="input.pack"
            :options="packs">
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('pack')">{{ errors.first('pack') }}</span>

          <p class="pt-4"> Type de pack <b style="color: #ff6141" >*</b> </p>
          <v-select
            v-validate="'required'"
            :reduce="rep => rep.key"
            class="w-full" label="name"
            v-model="TPack"
            :options="TypePack"
            :disabled="true"
          >
          </v-select>

          <p class="pt-4"> Frais d'adhésion du pack <b style="color: #ff6141" >*</b> </p>
          <vs-input
            v-validate="'required'"
            v-model="adMontant"
            disabled="true"
            class="w-full" />

          <p class="pt-4"> Montant du pack <b style="color: #ff6141" >*</b> </p>
          <vs-input-number
            class="w-full ml-0"
            v-model="input.amount"
            v-validate="'required'"
            name="amount"
            size="medium"
            readonly
            :disabled="true"
          />
          <span class="text-danger text-sm" v-show="errors.has('amount')">{{ errors.first('amount') }}</span>

          <p class="pt-4"> Nombre de personne <b style="color: #ff6141" >*</b> </p>
          <vs-input-number
            class="w-full ml-0"
            v-model="input.numberOfPerson"
            v-validate="'required'"
            size="medium"
            name="numberOfPerson"
            readonly
            :disabled="true"
            />
          <span class="text-danger text-sm" v-show="errors.has('numberOfPerson')">{{ errors.first('numberOfPerson') }}</span>


          <div v-if="TVmaj === true ">
            <p class="pt-4"> Réduction <b style="color: #ff6141" >*</b> </p>
            <vs-input
              v-validate="'required'"
              v-model="Tmaj"
              :disabled="true"
              class="w-full" />
          </div>

          <div v-if="TVmaj === false ">
            <p class="pt-4">Majoration <b style="color: #ff6141" >*</b> </p>
            <vs-input
              v-validate="'required'"
              v-model="Tmaj"
              :disabled="true"
              class="w-full" />
          </div>

         <div v-if="is_admin">
            <div v-if="CheckAssured">
            <p class="pt-4"> Prospect <b style="color: #ff6141" >*</b> </p>
                <vs-select
                  v-model="assured"
                  autocomplete
                  class="mt-5 w-full"
                  name="prospect"
                  v-validate="'required'">
                  <vs-select-item :key="item" :value="item.id" :text="item.username" v-for="item in prospects" />
                </vs-select>
          <p hidden class="pt-4" v-model="input.assured = assured"><b style="color: #ff6141" >*</b> </p>
         <span class="text-danger text-sm" v-show="errors.has('prospect')">{{ errors.first('prospect') }}</span>
          </div>
            <br/>
            <div v-if="Individual">
            <vs-checkbox
              color="success" v-model="input.is_recipient">
              Voulez-vous que le prospect soit un bénéficiaire?
            </vs-checkbox>
            </div>
          </div>


         <div v-if="is_admin">
            <div v-if="CheckAssured && Individual">
            <p class="pt-4"> Bénéficiaires <b style="color: #ff6141" >*</b> </p>
            <v-select
            multiple
            v-validate="'required'"
            name="recipients"
            :reduce="rep => rep.id"
            class="w-full" label="full_name"
            v-model="input.recipients"
            :options="recipients">
          </v-select>
            <span class="text-danger text-sm" v-show="errors.has('recipients')">{{ errors.first('recipients') }}</span>
            </div>
            <div v-if="CheckAssured && !Individual ">
            <p class="pt-4"> Bénéficiaire <b style="color: #ff6141" >*</b> </p>
            <v-select
            v-validate="'required'"
            name="Age_recipients1"
            :reduce="rep => rep.id"
            class="w-full" label="full_name"
            v-model="Age_recipients1"
            :options="recipients">
          </v-select>
            <span class="text-danger text-sm" v-show="errors.has('Age_recipients1')">{{ errors.first('Age_recipients1') }}</span>
            </div>
         </div>
         <div v-if="!is_admin">
          <div v-if="CheckAssured && Individual">
          <p class="pt-4"> Bénéficiaires <b style="color: #ff6141" >*</b> </p>
          <v-select
            multiple
            v-validate="'required'"
            name="recipients"
            :reduce="rep => rep.id"
            class="w-full" label="full_name"
            v-model="input.recipients"
            :options="recipients">
          </v-select>
            <span class="text-danger text-sm" v-show="errors.has('recipients')">{{ errors.first('recipients') }}</span>
          </div>
          <div v-if="CheckAssured && !Individual">
          <p class="pt-4"> Bénéficiaire <b style="color: #ff6141" >*</b> </p>
          <v-select
            v-validate="'required'"
            name="Age_recipients1"
            :reduce="rep => rep.id"
            class="w-full" label="full_name"
            v-model="Age_recipients1"
            :options="recipients">
          </v-select>
            <span class="text-danger text-sm" v-show="errors.has('Age_recipients1')">{{ errors.first('Age_recipients1') }}</span>
          </div>
            <br/>
            <div v-if="Individual">
            <vs-checkbox color="success" v-model="input.is_recipient">
              Voulez-vous être un bénéficiaire?
            </vs-checkbox>
            </div>
        </div>

        </div>
      </component>

      <div class="flex flex-wrap items-center p-6" slot="footer">
        <vs-button class="mr-6" @click="Prospect_validate">Soumettre</vs-button>
        <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Annuler</vs-button>
      </div>
  </vs-sidebar>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import { Validator } from 'vee-validate'
let is_admin = false
const user_role = JSON.parse(localStorage.getItem('userInfo')).role
if (user_role === 'admin') {
  is_admin = true
}

const dict = {
  custom: {
    pack: {
      required: 'Le champ pack est obligatoire'
    },
    amount: {
      required: 'Le champ montant est obligatoire'
    },
    numberOfPerson: {
      required: 'Le champ nombre de personne est obligatoire'
    },
    recipients: {
      required: 'Le champ bénéficiaire est obligatoire'
    },
    prospect: {
      required: 'Le champ prospect est obligatoire'
    }
  }
}

// register custom messages
Validator.localize('en', dict)

import VuePerfectScrollbar from 'vue-perfect-scrollbar'

const input_tempon = {
  amount: 0,
  pack: '',
  majMin:0,
  etat:'',
  is_recipient:false,
  assured:0,
  numberOfPerson: 0,
  recipients: [],
  recipients_delete: []
}

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    VuePerfectScrollbar,
    Datepicker
  },
  data () {
    return {
      activeButtom:false,
      recipients_old: [],
      recipients_verify: [],
      recipients: [],
      Age_recipients:[],
      Age_recipients1:'',
      prospects: [],
      TypePack: [
        {
          key: 'family',
          name: 'Familial'
        },
        {
          key: 'individual',
          name: 'Individuel'
        }
      ],
      adMontant:0,
      TPack:'',
      PackIndividualAmount:0,
      Tmaj:0,
      TVmaj:'',
      assured:'',
      is_admin,
      CheckNewAmout:false,
      Individual:false,
      CheckAssured:false,
      ages:'',
      montantFirst:'',
      prospect: '',
      montantparckbrut: 0,
      packs: [],
      tab: [],
      last_recipients:[],
      recipients_test1:[],
      countries: [],
      input1: JSON.parse(JSON.stringify(input_tempon)),
      input: JSON.parse(JSON.stringify(input_tempon)),
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: .60
      }
    }
  },
  watch: {
    Age_recipients1 (value) {
      this.Tmaj = ''
      this.TVmaj = ''
      for (let i = 0; i < this.input.recipients.length; i++) {
        this.input.recipients.pop()
      }
      if (this.TPack === 'individual') {
        if (this.CheckNewAmout === true) {
          this.montantparckbrut = this.PackIndividualAmount
        }
        if (value) {
          const recipient = value
          this.input.recipients.push(value)
          this.$http.post('recipientAge/', {recipient})
            .then((response) => {
              this.ages = response.data.age
              if (this.ages<18) {
                this.activeButtom = true
                this.TVmaj = true
                this.Tmaj = (this.montantparckbrut * 0.25)
                this.input.majMin = this.Tmaj
                this.input.etat = 'R'
                this.input.amount = this.montantparckbrut - (this.montantparckbrut * 0.25) + this.adMontant
                this.$vs.notify({
                  time:10000,
                  title: 'RÉDUCTION',
                  text: 'Vous avez une réduction de 25% sur le montant du pack.',
                  color: 'primary gradient',
                  position:'top-center'
                })
              } else if (this.ages>60) {
                this.activeButtom = true
                this.TVmaj = false
                this.Tmaj = (this.montantparckbrut * 0.30)
                this.input.majMin = this.Tmaj
                this.input.etat = 'M'
                this.input.amount = this.montantparckbrut + (this.montantparckbrut * 0.30) + this.adMontant
                this.$vs.notify({
                  time:10000,
                  title: 'MAJORATION',
                  text: ' Vous avez une majoration de 30% sur le montant du pack.',
                  color: 'primary gradient',
                  position:'top-center'
                })
              } else {
                this.TVmaj = ''
                this.activeButtom = true
                this.input.amount = this.PackIndividualAmount
                this.input.majMin = 0
                this.input.etat = 'n'
              }
            })
            .catch((error) => { console.log(error) })
        }

      }
    },

    assured (value) {
      if (this.TPack === 'individual') {
        if (value) {
          const prospect = value
          this.input.recipients = []
          this.$http.post('admin-user-recipients/', {prospect})
            .then((response) => {
              this.recipients = response.data
            })
            .catch((error) => { console.log(error) })
        }
      } else if (value) {
        const parent = value
        this.input.recipients = []
        this.$http.post('recipient_filter/', {parent})
          .then((response) => {
            this.recipients = response.data
          })
          .catch((error) => { console.log(error) })
      }

    },

    input: {
      handler () {
        if (this.input1.pack !== this.input.pack) {
          this.Age_recipients1 = ''
          for (let i = 0; i < this.packs.length; i++) {
            if (this.packs[i].id === this.input.pack) {
              this.adMontant = 0
              const pack = this.input.pack
              this.input.amount = this.packs[i].amount
              this.montantparckbrut = this.packs[i].amount
              this.TPack = this.packs[i].type
              this.input.numberOfPerson = this.packs[i].numberOfPerson
              this.$http.post('membershipFeeAmount/', {pack})
                .then((response) => {
                  this.adMontant = response.data.membershipFee
                })
                .catch((error) => {
                  console.log(error)
                })
              if (this.TPack === 'individual') {
                this.Individual = false
                if (!this.is_admin) {
                  this.getAllUserRecipients()
                }
              } else {
                this.Individual = true
              }
              this.CheckAssured = this.input.amount !== 0
              if (Object.entries(this.data).length !== 0) {
                for (let i = 0; i < this.data.recipients.length; i++) {
                  if (this.Individual === false) {
                    this.Age_recipients1 = this.data.recipients[i]
                    this.recipients_old.push(this.input.recipients[i].id)
                    // this.input.recipients.push(this.input.recipients[i].id)
                    this.$http.get(`packs/${this.input.pack}/`)
                      .then((response) => {
                        this.PackIndividualAmount = response.data.amount
                      })
                    const recipient = this.input.recipients[i].id
                    this.$http.post('recipientAge/', {recipient})
                      .then((response) => {
                        this.ages = response.data.age
                      })

                  } else if (this.Individual === true) {
                    this.input.recipients.push(this.data.recipients[i])
                    this.recipients_old.push(this.input.recipients[i].id)
                    // this.input.recipients.push(this.input.recipients[i].id)

                  }

                }

              }
              break
            }
          }
        }
        this.input1 = JSON.parse(JSON.stringify(this.input))
      },
      deep: true
    },

    isSidebarActive (val) {
      if (!val) return
      if (Object.entries(this.data).length === 0) {
        this.initValues()
        this.Age_recipients = []
        this.assured = ''
        this.TPack = ''
        this.Tmaj = ''
        this.TVmaj = ''
        this.adMontant = 0
        this.Individual = false
        this.CheckAssured = false
        this.$validator.reset()
      } else {
        this.input = JSON.parse(JSON.stringify(this.data))
        this.assured = this.input.assured
        for (let i = 0; i < this.input.recipients.length; i++) {
          this.recipients_old.pop()
        }
        if (this.TPack === 'individual') {
          this.Age_recipients1 = this.input.recipients[0].id
          this.recipients_old.push(this.input.recipients[0].id)
          this.CheckNewAmout = true
        } else if (this.TPack === 'family') {
          this.CheckNewAmout = false
          for (let i = 0; i < this.input.recipients.length; i++) {
            this.recipients_old.push(this.input.recipients[i].id)
          }
        }
        this.initValues()
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
          // this.$validator.reset()
          // this.initValues()
        }
      }
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag }
  },
  methods: {
    initValues () {
      if (this.data.id) return
      this.input = JSON.parse(JSON.stringify(input_tempon))
    },
    Prospect_validate () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.submitData()
        }
      })
    },
    async submitData () {
      this.$vs.loading()
      const input = JSON.parse(JSON.stringify(this.input))
      input.recipients_delete = []
      for (let i = 0; i < this.recipients_old.length; i++) {
        let ok = false
        for (let j = 0; j < input.recipients.length; j++) {
          if (this.recipients_old[i] === input.recipients[j]) {
            ok = true
            break
          }
        }
        if (!ok) {
          input.recipients_delete.push(this.recipients_old[i])
        }
      }
      //console.log('voir le input maintenant', input)
      let url = 'userHasPacks/'
      let methods = 'post'
      const message = {
        error: 'Votre enrégistrement à échouer.',
        success: 'Le pack est enrégistré avec succès.',
        titre: 'ENREGISTREMENT'
      }
      if (input.id) {
        url += `${input.id}/`
        methods = 'put'
        message.success = 'Le pack est modifié avec succès.'
      }

      this.$http[methods](url, input)
        .then(() => {
          window.getProspect.getAllUserHasPacks()
          this.$vs.notify({
            time:4000,
            title: message.titre,
            text: message.success,
            color: 'success',
            position:'top-right'
          })
          this.$emit('closeSidebar')
          this.initValues()
        })
        .catch((error) => {
          this.$vs.notify({
            time:8000,
            title: 'ATTENTION',
            text:error.response.data,
            color: 'warning',
            position:'top-center'
          })
          this.$vs.notify({
            time:8000,
            title: 'ENREGISTREMENT',
            text: 'L\'enregistrement a échoué',
            color: 'danger',
            position:'top-right'
          })
          this.$vs.loading.close()
        })
    },
    updateCurrImg (input) {
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.dataImg = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    getAllPacks () {
      this.$http.get('packs/')
        .then((response) => {
          this.packs = response.data
        })
        .catch((error) => {
          console.log('error: ', error)
        })
    },
    getAllUserRecipients () {
      this.$http.get('user-recipients')
        .then((response) => {
          this.recipients = response.data
        })
        .catch((error) => {
          console.log('error: ', error)
        })
    }

  },
  created () {
    this.getAllPacks()
    this.$http.get('prospects/')
      .then((response) => { this.prospects = response.data })
      .catch((error) => { console.log(error) })

    if (!this.is_admin) {
      this.$http.get('recipients')
        .then((response) => {
          this.recipients = response.data
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

    &:not(.ps) {
      overflow-y: auto;
    }
}
</style>
